import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as AWS from "aws-sdk";
import { environment } from '../../../environments/environment';
import Constants from '../constant/Constants';
import * as JWT from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  public applicationInfo;

  public versionInfo;
  public sessionTimer;
  public AppS3: AWS.S3;
  constructor(public injector: Injector, private http: HttpClient) {

  }

  async LoadAWSS3Config(iseulla) {
    let token: any
    token = JWT(localStorage.getItem(Constants.STORAGE.TOKEN));
    if (new Date(token.exp * 1000) < new Date()) {
      var tk = await this.loadnewtoken()
      if (tk) {
        localStorage.setItem(Constants.STORAGE.ACCESSTOKEN, tk.AccessToken);
        localStorage.setItem(Constants.STORAGE.TOKEN, tk.IdToken);
        if (!iseulla) {
          return this.CreateS3Object();
        }
      }
    }
    else {
      if (!iseulla) {
        return this.CreateS3Object();
      }
    }

  }
  CreateS3Object() {
    var bucketurl = 'sventa-edl-data-test'
    var identityPoolId;
    if (window.location.hostname == 'localhost') {

      bucketurl = StringFormat(environment.AWSINFO.rekognitionBucket, 'test');
      /** 
      identityPoolId ="ap-south-1:fae03a64-f533-4d13-904c-a4298afe0da4";
      bucketurl = StringFormat(environment.AWSINFO.rekognitionBucket, 'prod');
      environment.AWSINFO.rekognitionBucket = bucketurl;
      AWS.config.update({ //testing... 12/29
        region: 'ap-south-1',
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: identityPoolId,
          Logins: {
            'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_w3fInVJ3w':localStorage.getItem(Constants.STORAGE.IDTOKEN),
          },
        })
      });*/



      identityPoolId = "ap-south-1:fae03a64-f533-4d13-904c-a4298afe0da4";

      environment.AWSINFO.rekognitionBucket = bucketurl;
      AWS.config.update({ //testing... 12/29
        region: 'ap-south-1',
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: identityPoolId,
          Logins: {
            'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_5ZGVtosC5': localStorage.getItem(Constants.STORAGE.IDTOKEN),
          },
        })
      });
    }
    else if (window.location.hostname.indexOf('dev') !== -1) {
      identityPoolId = "ap-south-1:fae03a64-f533-4d13-904c-a4298afe0da4";
      bucketurl = StringFormat(environment.AWSINFO.rekognitionBucket, 'dev');
      environment.AWSINFO.rekognitionBucket = bucketurl;
      AWS.config.update({ //testing... 12/29
        region: 'ap-south-1',
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: identityPoolId,
          Logins: {
            'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_5ZGVtosC5': localStorage.getItem(Constants.STORAGE.IDTOKEN),
          },
        })
      });
    }
    else if (window.location.hostname.indexOf('test') !== -1) {
      identityPoolId = "ap-south-1:fae03a64-f533-4d13-904c-a4298afe0da4";
      bucketurl = StringFormat(environment.AWSINFO.rekognitionBucket, 'test');
      environment.AWSINFO.rekognitionBucket = bucketurl;
      AWS.config.update({ //testing... 12/29
        region: 'ap-south-1',

        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: identityPoolId,
          Logins: {
            'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_5ZGVtosC5': localStorage.getItem(Constants.STORAGE.IDTOKEN),
          },

        })
      });
    }
    else {
      identityPoolId = "ap-southeast-2:27b7ecd3-041a-4700-b07b-af4d62edb8ea";
      bucketurl = StringFormat(environment.AWSINFO.rekognitionBucket, 'prod');
      environment.AWSINFO.rekognitionBucket = bucketurl;
      AWS.config.update({ //testing... 12/29
        region: 'ap-southeast-2',
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: identityPoolId,
          Logins: {
            'cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_Ja6dp7Wcx': localStorage.getItem(Constants.STORAGE.IDTOKEN),
          },
        })
      });
    }


    return new AWS.S3({ params: { Bucket: bucketurl } })
  }


  gethostname(url) {
    if (window.location.hostname == 'localhost')
      return StringFormat(url, 'test');
    else if (window.location.hostname.indexOf('dev') !== -1)
      return StringFormat(url, 'dev');
    else if (window.location.hostname.indexOf('test') !== -1)
      return StringFormat(url, 'test');
    else
      return StringFormat(url, 'prod');

  }

  async loadnewtoken() {

    var refreshtokenurl = this.gethostname(environment.AWSINFO.AuthApiBaseurl)

    var tokens = await this.http.get<any>(refreshtokenurl + '/token?appname=' + environment.AWSINFO.AppName + '&refreshtoken=' + localStorage.getItem(Constants.STORAGE.REFRESHTOKEN)).toPromise();
    localStorage.setItem(Constants.STORAGE.ACCESSTOKEN, tokens.AuthenticationResult.AccessToken);
    localStorage.setItem(Constants.STORAGE.TOKEN, tokens.AuthenticationResult.IdToken);
    return tokens.AuthenticationResult;

  }
  loadconfigApi() {
    const http = this.injector.get(HttpClient);
    return http.get('./assets/config/app-config.json');
  }


  loadConfigOnAppInit() {
    const http = this.injector.get(HttpClient);



    http.get('./assets/config/app-config.json').toPromise().then(data => {
      this.applicationInfo = data;


    });

    return http.get('./assets/config/app-version.json').toPromise().then(data => {
      this.versionInfo = data;

    });


  }

  // get the configuration
  get Config() {
    return this.applicationInfo;
  }

  get ConfigAmplify() {
    return this.applicationInfo.amplify;
  }

  get applicationName() {
    return this.applicationInfo.applicationName;
  }

  get languageTotranslate() {
    return this.applicationInfo.translateLang;
  }

  get isTranslate() {
    return this.applicationInfo.istranslate;
  }

  get HeatMapData() {
    return this.applicationInfo.heatMap;
  }


  // get the Version 
  get Version() {
    return this.versionInfo;
  }

  get applicationVersion() {
    return this.versionInfo.version;
  }

  get SessionTimer() {
    return this.sessionTimer;
  }


  set SessionTimer(value: string) {
    this.sessionTimer = value;
  }


  get ConfigAPPINFO() {
    return this.applicationInfo.APPINFO;
  }
  get ConfigAWSINFO() {
    return this.applicationInfo.AWSINFO;
  }
}
const StringFormat = (str: string, ...args: string[]) =>
  str.replace(/{(\d+)}/g, (match, index) => args[index] || '')
